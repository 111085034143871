<script>
import Navbar from "@/components/navbar";
import Services from "@/components/services";
import Features from "@/components/features";
import Contact from "@/components/contact";
import Footer from "@/components/footer";

/**
 * index-8 component
 */
export default {
  name: "index-8",
  components: {
    Navbar,
    Services,
    Features,
    Contact,
    Footer,
  },
};
</script>

<template>
  <div>
    <Navbar />
    <div>
      <!--START HOME-->
      <section
        class="section section-lg home-half"
        id="home"
        :style="{'background-image': 'url(' + require('@/assets/iStock-544756566.jpg') + ')' , 'background-size': 'cover', 'background-position': 'center'}"
      >
        <div class="bg-overlay"></div>
        <div class="display-table">
          <div class="home-cell-bottom">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 text-white text-center">
                  <h1 class="home-title">Let’s make your life easy.</h1>
                  <h2>Free up your free time!</h2>
                  <div class="margin-t-30" style="">
                    <video width="2548" height="auto" autoplay loop style="max-width:100%">
                      <source src="@/assets/iStock-1185948703.mp4" type="video/mp4">
                      <source src="@/assets/iStock-1185948703.webm" type="video/webm">
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--END HOME-->
      <Services />
      <Features />
      <Contact />
      <Footer />
    </div>
  </div>
</template>