<template>
  <div>
    <!-- CONTACT FORM START-->
    <section class="section" id="contact">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 offset-lg-2">
            <h1 class="section-title text-center">Get In Touch</h1>
            <div class="section-title-border margin-t-20"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <div class="mt-4 pt-4">
              <p class="mt-4">
                <span class="h5">Office Address:</span>
                <br />
                <span class="text-muted d-block mt-2">8805 Tamiami Trail N #222, Naples, FL 34108</span>
              </p>
              <p class="mt-4">
                <span class="h5"><i class="pe-7s-call"></i>&nbsp; Phone:</span>
                <br />
                <a href="tel:+1239-600-1773" class="text-muted d-block mt-2"> 239-600-1773</a>
              </p>
              <p class="mt-4">
                <span class="h5">Working Hours:</span>
                <br />
                <span class="text-muted d-block mt-2">9:00AM To 6:00PM</span>
              </p>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="custom-form mt-4 pt-4">
              <div id="message"></div>
              <form method="post" action="/" name="contact-form" id="contact-form">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group mt-2">
                      <input
                        name="name"
                        id="name"
                        type="text"
                        class="form-control"
                        placeholder="Your name*"
                        v-model="form.name"
                        required
                      />
                      <span class="missing" ref="name"></span>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group mt-2">
                      <input
                        name="email"
                        id="email"
                        type="email"
                        class="form-control"
                        placeholder="Your email*"
                        v-model="form.email"
                        required
                      />
                      <span class="missing" ref="email"></span>
                      <input
                        name="subject"
                        type="text"
                        class="form-subject"
                        v-model="form.subject"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group mt-2">
                      <textarea
                        name="comments"
                        id="comments"
                        rows="4"
                        class="form-control"
                        placeholder="Your message..."
                        v-model="form.comments"
                        required
                      ></textarea>
                      <span class="missing" ref="comments"></span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 text-right">
                    <div ref="spinner" class="spinner hide">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background-color: rgb(255, 255, 255); display: block; shape-rendering: auto; background-position: initial initial; background-repeat: initial initial;" width="40px" height="40px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                        <g>
                          <path d="M50 15A35 35 0 1 0 74.74873734152916 25.251262658470843" fill="none" stroke="#027dbb" stroke-width="12"></path>
                          <path d="M49 3L49 27L61 15L49 3" fill="#027dbb"></path>
                          <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                        </g></svg>
                    </div>
                    <input
                      type="submit"
                      id="submit"
                      class="submitBnt btn btn-custom"
                      value="Send Message"
                      ref="sendb"
                      @click="sendEmail"
                    />
                  </div>
                </div>
                <div class="success hide" ref="response">Message Sent. Thank you!</div>
                <div class="error hide" ref="error">There was an error, please call us.</div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- CONTACT FORM END-->
  </div>
</template>

<script>
const axios = require('axios');

export default {
  data: function() {
    return {
      form: {
        name: '',
        subject: '',
        email: '',
        comments: ''
      },
      missing: {
        name: false,
        email: false,
        comments: false,
      }
    }
  },
  methods:{
    sendEmail(event) {
      event.preventDefault();
      let errors = false;
      this.$refs.name.innerText = errors = this.form.name ? '' : 'Name required!';
      this.$refs.email.innerText = errors = this.form.email ? '' : 'Email required!';
      this.$refs.comments.innerText = errors = this.form.comments ? '' : 'Comments required!';
      if (errors) {
        return;
      }
      this.$refs.spinner.classList.toggle('hide');
      axios.post('/php/contact.php', this.form)
      .then(res => {
        console.log(res.data.response);
        this.$refs.spinner.classList.remove('hide');
        if (res.data && res.data.response && res.data.response == 'success') {
          this.$refs.response.classList.remove('hide');
          console.log('Email sent', this.$refs.response);
        } else {
          this.$refs.error.classList.remove('hide');
          console.error('Email not sent');
        }
        this.$refs.spinner.classList.add('hide');
        this.$refs.sendb.classList.add('hide');
      });
    }
  }
};
</script>