<script>
export default {};
</script>

<template>
  <!--START SERVICES-->
  <section class="section pt-5" id="services">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <h1 class="section-title text-center margin-t-50">Our Services</h1>
          <div class="section-title-border margin-t-20"></div>
        </div>
      </div>
      <div class="row margin-t-30">
        <div class="col-lg-4 margin-t-20">
          <div class="services-box text-center hover-effect">
            <i class="pe-7s-science text-custom"></i>
            <h4 class="padding-t-15">Covid-19 Cleaning</h4>
            <p
              class="padding-t-15 text-muted"
            >Corona virus can live on the surfaces of your home for up to 24-72 hours. We offers disinfecting deep cleans of domestic properties.</p>
          </div>
        </div>
        <div class="col-lg-4 margin-t-20">
          <div class="services-box text-center hover-effect">
            <i class="pe-7s-home text-custom"></i>
            <h4 class="padding-t-15">Housekeeping Services</h4>
            <p
              class="padding-t-15 text-muted"
            >Are you a fan of sterility and always want to have space around you neat and tidy? Entrust your house to our professional’s housekeepers!</p>
          </div>
        </div>
        <div class="col-lg-4 margin-t-20">
          <div class="services-box text-center hover-effect">
            <i class="pe-7s-portfolio text-custom"></i>
            <h4 class="padding-t-15">Office Cleaning</h4>
            <p
              class="padding-t-15 text-muted"
            >Are you a busy manager who wants to organize workflow at your office as best as you can? We offer flexible Daily/weekly/bi weekly Cleaning</p>
          </div>
        </div>
      </div>
      <div class="row margin-t-30">
        <div class="col-lg-4 margin-t-20">
          <div class="services-box text-center hover-effect">
            <i class="pe-7s-leaf text-custom"></i>
            <h4 class="padding-t-15">Allergy Solution</h4>
            <p
              class="padding-t-15 text-muted"
            >We can help with your allergy problems so your home is no longer a source of sneezing and itchy eyes</p>
          </div>
        </div>
        <div class="col-lg-4 margin-t-20">
          <div class="services-box text-center hover-effect">
            <i class="pe-7s-edit text-custom"></i>
            <h4 class="padding-t-15">Custom Cleaning Service</h4>
            <p
              class="padding-t-15 text-muted"
            >We offer customized plans for each home because we understand that each client needs and availability</p>
          </div>
        </div>
        <div class="col-lg-4 margin-t-20">
          <div class="services-box text-center hover-effect">
            <i class="pe-7s-diamond text-custom"></i>
            <h4 class="padding-t-15">Luxury Cleaning</h4>
            <p
              class="padding-t-15 text-muted"
            >Naples and Bonita Springs cleaning services for any budget. Every case is estimated in person based on client's needs.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--START SERVICES-->
</template>