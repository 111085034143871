<script>
export default {};
</script>

<template>
  <div>
    <!--start ABOUT US-->
    <section class="section bg-light" id="features">
      <div class="container">
        <div class="row vertical-content">
          <div class="col-lg-5">
            <div class="features-box">
              <h3>Why Choose Us?</h3>
              <p
                class="text-muted web-desc"
              >For over 20 years, our trained staff have provided exceptional, customized home cleaning and maid services</p>
              <ul class="text-muted list-unstyled margin-t-30 features-item-list">
                <li class>Give yourself the gift of a clean home.</li>
                <li class>Use your time to do what you love.</li>
              </ul>
              <a v-scroll-to="'#contact'" href="javascript: void(0);" class="btn btn-custom margin-t-30 waves-effect waves-light">
                Reach Out
                <i class="mdi mdi-arrow-right"></i>
              </a>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="features-img features-right text-right">
              <img src="@/assets/images/online-world.svg" alt="macbook image" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--END ABOUT US-->
    <!--START WEBSITE-DESCRIPTION-->
    <section class="section section-lg bg-web-desc">
      <div class="bg-overlay"></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h2 class="text-white">Free Estimate</h2>
            <p
              class="padding-t-15 home-desc"
            >Bonita.cleaning house cleaning rates vary based on each client’s space and specific needs. We offer customized plans for each home.</p>
          </div>
        </div>
      </div>

    </section>

    <!--END WEBSITE-DESCRIPTION-->
  </div>
</template>